import React from 'react';
import styles from './HeaderSearchTechniques.module.css';

const HeaderSearchTechniques = (props) => {
  const { show } = props;

  return (
    <div>
      <div
        className={styles.techniquesList}
        style={{
          display: show ? 'block' : 'none',
        }}
      >
        <div className={styles.techniquesTitle}>
          고급 검색 기능
          <div>
            (명령어는 <span className={styles.textDecoration}>대문자</span>이며, 명령어끼리 조합 가능)
          </div>
        </div>
        <div className={styles.techniquesTop}>
          <span className={styles.keywordTitle}>명령어</span>
          <span className={styles.example}>예시</span>
          <span className={styles.description}>설명</span>
        </div>
        <div className={styles.borderLine}></div>
        <div className={styles.techniques}>
          <span className={styles.keyword}>AND</span>
          <span className={styles.exampleKeyword}>
            명예훼손 <span className={styles.strongText}>AND</span> 모욕
          </span>
          <span className={styles.descriptionKeyword}>명예훼손과 모욕을 모두 포함한 결과</span>
        </div>
        <div className={styles.techniques}>
          <span className={styles.keyword}>OR</span>
          <span className={styles.exampleKeyword}>
            명예훼손<span className={styles.strongText}> OR</span> 모욕{' '}
          </span>
          <span className={styles.descriptionKeyword}>명예훼손 또는 모욕 중 하나 이상을 포함한 결과</span>
        </div>
        <div className={styles.techniques}>
          <span className={styles.keyword}>"&nbsp;&nbsp;&nbsp;"</span>
          <span className={styles.exampleKeyword}>
            <span className={styles.strongText}>"</span>연구비 횡령<span className={styles.strongText}>"</span>
          </span>
          <span className={styles.descriptionKeyword}>큰따옴표 내 “연구비 횡령”과 정확히 일치하는 결과</span>
        </div>
        <div className={styles.techniques}>
          <span className={styles.keywordMinus}>-</span>
          <span className={styles.exampleKeyword}>
            주취감경 <span className={styles.strongText}>-</span>자동차
          </span>
          <span className={styles.descriptionKeyword}>주취감경 중 자동차는 제외한 결과</span>
        </div>
      </div>
    </div>
  );
};

export default HeaderSearchTechniques;
