import React from 'react';
import styles from './404.module.css';
import Header from '@src/components/Base/Header/StandardHeader';
import MobileSearchbar from '@src/components/Base/Header/MobileSearchbar';
import { Container, Button } from 'reactstrap';
import WindowDimension from '@src/components/Base/WindowDimension';
import Footer from '@src/components/Base/Footer/Footer';
import DefaultHead from '@src/components/Base/Head/DefaultHead';
import Meta from '@src/components/Base/Head/Meta';

export default function Custom404() {
  // Style

  return (
    <div>
      <DefaultHead />
      <Meta title={`리걸엔진 | 404`} description={`404 error`} url={`404`} />
      <Header imgSrc='/logo.svg' />
      {/* MobileSearchbar 추가됨  */}
      <MobileSearchbar />
      <Container className={styles.errorContainer}>
        <div className={styles.errorContentBody}>
          <div className={styles.errorTitle}>404</div>
          <div className={styles.errorMessage}>
            <div>페이지가 존재하지 않거나,</div>
            <div>사용할 수 없는 페이지입니다.</div>
            <div>입력하신 주소가 정확한지 확인해주세요.</div>
          </div>
          <Button className={styles.errorButton} href='/'>
            메인페이지
          </Button>
        </div>
      </Container>
      <Footer />
    </div>
  );
}
