import React, { useState } from 'react';
import styles from './HeaderSearchbar.module.css';
import SearchTechniques from './HeaderSearchTechniques';

const HeaderSearchbar = () => {
  const [query, setQuery] = useState('');
  const [show, setShow] = useState(false);

  const handleChangeQuery = (e) => {
    e.preventDefault();
    setQuery(e.target.value);
  };

  const handleSummit = (e) => {
    e.preventDefault();
    if (query === undefined || query === null || query === '') {
      window.location.href = `/search?q=""`;
    } else {
      window.location.href = `/search?q=${encodeURIComponent(query)}`;
    }
  };

  const openTechniques = () => {
    setShow(true);
  };

  const handleClick = () => {
    setShow(!show);
  };

  return (
    <div>
      <div className={styles.searchbarContanier}>
        <form onSubmit={handleSummit} action='search'>
          <input
            type='search'
            name='q'
            className={styles.searchbar}
            placeholder='사건번호, 키워드, 법령 등을 검색해 보세요'
            onChange={handleChangeQuery}
            onClick={openTechniques}
            autoComplete='off'
          />
          <button type='submit' className={styles.searchbarBtn}>
            검색
          </button>
        </form>
        <SearchTechniques show={show} onClick={handleClick} />
      </div>
      <div
        className={styles.techniquesListOpen}
        style={{
          display: show ? 'block' : 'none',
          zIndex: show ? '1041' : 'none',
          opacity: show ? '0.5' : '0',
        }}
        onClick={handleClick}
      ></div>
    </div>
  );
};

export default HeaderSearchbar;
